import Pusher from 'pusher-js';
import * as PusherTypes from 'pusher-js';

export const usePusherStore = defineStore('pusherStore', () => {
   const config = useRuntimeConfig();
   const { clientToken } = useAuthClientStorage();
   const { therapistToken } = useAuthTherapistStorage();
   const pusher: Ref<PusherTypes.default | undefined> = ref(undefined);
   const privateVideoChannel: Ref<PusherTypes.Channel | undefined> =
      ref(undefined);
   const privateChatChannel: Ref<PusherTypes.Channel | undefined> =
      ref(undefined);
   const connectToPusher = (isClient?: boolean) => {
      const token =
         isClient ?
            clientToken.value?.access_token
         :  therapistToken.value?.access_token;

      pusher.value = new Pusher(config.public.PUSHER_KEY, {
         cluster: 'ap2',
         authEndpoint: `${config.public.API_BASE_URL}/pusher/auth`,
         auth: {
            headers: {
               Authorization: `Token ${token}`,
            },
         },
         forceTLS: true,
      });

      return pusher.value;
   };
   const subscribeVideoToChannel = (privateChannelId: string) => {
      const channelName = `presence-session-${privateChannelId}`;
      privateVideoChannel.value = pusher.value?.subscribe(channelName);
   };
   const subscribeChatToChannel = (clientId: string, therapistId: string) => {
      const channelName = `private-chat-${clientId}-${therapistId}`;
      privateChatChannel.value = pusher.value?.subscribe(channelName);
   };

   const sendTriggerForJoinAlternative = (isClient?: boolean) => {
      privateVideoChannel.value?.trigger('client-joined-alternative-link', {
         role: isClient ? 'client' : 'therapist',
      });
   };

   const sendTriggerForJoiningCall = (isClient?: boolean) => {
      privateVideoChannel.value?.trigger('client-video-join', {
         time: new Date().toISOString(),
         role: isClient ? 'client' : 'therapist',
      });
   };

   const sendTriggerForLeavingCall = (isClient?: boolean) => {
      privateVideoChannel.value?.trigger('client-video-leave', {
         time: new Date().toISOString(),
         role: isClient ? 'client' : 'therapist',
      });
   };

   const removeVideoChannels = () => {
      privateVideoChannel.value?.unsubscribe();
      privateVideoChannel.value?.unbind('client-joined-alternative-link');
      privateVideoChannel.value?.disconnect();
   };
   const removeChatChannels = (clientId: string, therapistId: string) => {
      privateChatChannel.value?.unsubscribe();
      privateChatChannel.value?.disconnect();
      privateChatChannel.value = undefined;
      pusher.value?.connection.unbind('state_change');
      pusher.value?.unsubscribe('private-chat-' + clientId + '-' + therapistId);
   };

   return {
      pusher,
      privateVideoChannel,
      privateChatChannel,
      subscribeChatToChannel,
      subscribeVideoToChannel,
      sendTriggerForJoinAlternative,
      removeVideoChannels,
      sendTriggerForJoiningCall,
      sendTriggerForLeavingCall,
      removeChatChannels,
      connectToPusher,
   };
});
